var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-reference=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reference") || (depth0 != null ? lookupProperty(depth0,"reference") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"reference","hash":{},"data":data,"loc":{"start":{"line":4,"column":77},"end":{"line":4,"column":90}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-status=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data,"loc":{"start":{"line":4,"column":126},"end":{"line":4,"column":136}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-lg-3\" style=\"padding-right:0px;\">\n                            <span class=\"course-room-url-prefix\">https://"
    + alias4(((helper = (helper = lookupProperty(helpers,"prefix") || (depth0 != null ? lookupProperty(depth0,"prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prefix","hash":{},"data":data,"loc":{"start":{"line":12,"column":73},"end":{"line":12,"column":83}}}) : helper)))
    + ".goboard.com/</span>\n                        </div>\n                        <div class=\"col-lg-2\" style=\"padding-left:0px;\">\n                            <input type=\"text\" class=\"form-control course-room-url\" placeholder=\"Room Number\" data-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":15,"column":120},"end":{"line":15,"column":127}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url_input") || (depth0 != null ? lookupProperty(depth0,"url_input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url_input","hash":{},"data":data,"loc":{"start":{"line":15,"column":136},"end":{"line":15,"column":149}}}) : helper)))
    + "\">\n                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-lg-4\">\n                            <input type=\"text\" class=\"form-control course-room-url\" placeholder=\"https://\" data-url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":19,"column":117},"end":{"line":19,"column":124}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url_input") || (depth0 != null ? lookupProperty(depth0,"url_input") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url_input","hash":{},"data":data,"loc":{"start":{"line":19,"column":133},"end":{"line":19,"column":146}}}) : helper)))
    + "\">\n                        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row course-entry\" data-sort=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sort") || (depth0 != null ? lookupProperty(depth0,"sort") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sort","hash":{},"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":49}}}) : helper)))
    + "\">\n    <div class=\"col-sm-12\">\n        <form class=\"course-settings\">\n            <div class=\"course\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":42}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reference") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":98}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":99},"end":{"line":4,"column":144}}})) != null ? stack1 : "")
    + ">\n                <div class=\"row\">\n                    <div class=\"col-lg-2\">\n                        <i class=\"fas fa-star\"></i>\n                        <span class=\"code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":51}}}) : helper)))
    + "</span>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"prefix") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"col-lg-1\">\n                        <span class=\"tooltip-help-wrapper inline\">\n                            <i data-toggle=\"tooltip\" data-placement=\"bottom\" title=\"Add your unique GoBoard URL here so that students know where to go.\" class=\"fas fa-question-circle\"></i>\n                        </span>\n                    </div>\n                    <div class=\"col-lg-3\">\n                        <select class=\"form-control course-room-capacity\">\n                            <option value=\"\" disabled selected hidden>(Select Number)</option>\n                            <option value=\"1\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"capacity1") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":46},"end":{"line":30,"column":78}}})) != null ? stack1 : "")
    + ">1</option>\n                            <option value=\"2\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"capacity2") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":46},"end":{"line":31,"column":78}}})) != null ? stack1 : "")
    + ">2</option>\n                            <option value=\"3\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"capacity3") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":46},"end":{"line":32,"column":78}}})) != null ? stack1 : "")
    + ">3</option>\n                            <option value=\"4\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"capacity4") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":46},"end":{"line":33,"column":78}}})) != null ? stack1 : "")
    + ">4</option>\n                            <option value=\"5\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"capacity5") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":46},"end":{"line":34,"column":78}}})) != null ? stack1 : "")
    + ">5</option>\n                        </select>\n                    </div>\n                </div>\n            </div>\n        </form>\n    </div>\n</div>\n";
},"useData":true});