const databases = {
    default: "tms-board",
    public: "tms-board-public",
    arkansasrazorbacks: "tms-board-arkansasrazorbacks",
    bucks: "tms-board-bucks",
    gators: "tms-board-gators",
    gsusas: "tms-board-gsusas",
    reedleycollege: "tms-board-reedleycollege",
    uky: "tms-board-uky",
    unm: "tms-board-unm",
    wvu: "tms-board-wvu"
};

const getDatabaseForPrefix = prefix => {
    prefix = !prefix || prefix === "" ? "public" : prefix;
    prefix = databases.hasOwnProperty(prefix) ? prefix : "default";

    let name = databases[prefix];

    return `https://${name}.firebaseio.com`;
};

const getDatabaseForRoom = name => {
    let parts = name ? name.split("_") : "";

    return getDatabaseForPrefix(parts.length > 1 ? parts[0] : null);
};

const getAppNameForRoom = name => {
    let prefix = name ? name.split("_") : "";

    prefix = prefix.length > 1 ? prefix[0] : null;
    prefix = !prefix || prefix === "" ? "public" : prefix;

    let appName = databases.hasOwnProperty(prefix) ? prefix : "[DEFAULT]";

    return appName;
};

module.exports = {
    getDatabaseForPrefix,
    getDatabaseForRoom,
    getAppNameForRoom
};
