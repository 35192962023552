import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";

import goboardDatabaseHelper from './goboard-helper.js';


class FirebaseConfig {
    constructor(databaseURL) {
        this.apiKey = process.env.FIREBASE_API_KEY;
        this.authDomain = process.env.FIREBASE_AUTH_DOMAIN;
        this.databaseURL = databaseURL || process.env.FIREBASE_DATABASE_URL;
        this.projectId = process.env.FIREBASE_PROJECT_ID;
        this.storageBucket = process.env.FIREBASE_STORAGE_BUCKET;
        this.messagingSenderId = process.env.FIREBASE_MESSAGING_SENDER_ID;
        this.appId = process.env.FIREBASE_APP_ID;
    }
}

class CourseTutorStatus {
    constructor(data) {
        this.reference = data.reference;
        this.queue_reference = data.queue_reference;
        this.status = parseInt(data.status, 10) || 0;
        this.url = data.url || '';
    }
}

class CourseTutorQueue {
    constructor(app, data) {
        this.uuid = app.cookieStore.getUserID();
        this.user_id = app.cookieStore.getUserData().id;
        this.status = parseInt(data.status, 10) || 0;
        this.timestamp = app.databaseStore.firebase.database.ServerValue.TIMESTAMP;
    }
}

class DropInRoonObject {
    constructor(app, data) {
        this.uuid = app.cookieStore.getUserID();
        this.user_id = app.cookieStore.getUserData().id;
        this.course_name = app.getCourseNameByID(data.course_reference);
        this.course_reference = data.course_reference;
        this.tutor_reference = data.tutor_reference;
        this.url = window.location.origin + '/#/room/' + data.course_reference + '/' + data.tutor_reference;
        this.timestamp = app.databaseStore.firebase.database.ServerValue.TIMESTAMP;
    }
}


export default class DatabaseStore {
    constructor(app) {
        this.app = app;

        firebase.initializeApp(new FirebaseConfig());

        this.firebase = firebase;

        this.realtime = this.firebase.database();
        this.firestore = this.firebase.firestore();

        this.course_reference_name = this.app.schoolhelper.getGoboardPrefix() + '/courses';
    }

    getTutoringStatuses() {
        let coursesRef = this.realtime.ref(this.course_reference_name);

        return coursesRef.once('value');
    }

    getTutoringCourse(course_id, reference) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(reference || ' ');

        return courseTutorChildRef.once('value');
    }
    saveTutoringCourse(course_id, course_tutor) {
        let user_id = this.app.cookieStore.getUserData().id;

        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors');

        return courseChildRef
                .orderByChild("user_id")
                .equalTo(user_id)
                .once("value", child => {
                    if (child.exists()) {
                        let user_id = this.app.cookieStore.getUserData().id;

                        child.forEach((childSnapshot) => {
                            let childKey = childSnapshot.key,
                                    childData = childSnapshot.val();

                            if (childData.user_id === user_id) {
                                this.updateTutoringCourse(course_id, childKey, course_tutor);
                            }
                        });
                    } else {
                        let newChildRef = courseChildRef.push();

                        this.app.cookieStore.addTutorCourseReferenceItem(course_id, newChildRef.key);

                        return newChildRef
                                .set(course_tutor)
                                .then(() => {
                                    //console.log('Insert Complete - Tutor');
                                }, function (reason) {
                                    alert('The search failed...');

                                    console.log('The search failed... ', reason);
                                });
                    }
                }, function (reason) {
                    alert('The search failed...');

                    console.log('The search failed... ', reason);
                });
    }
    updateTutoringCourse(course_id, reference, course_tutor) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(reference);

        return courseTutorChildRef.update(course_tutor, (error) => {
            if (error) {
                alert('The write failed...');

                console.log('The write failed... ', error);
            } else {
                this.app.cookieStore.removeTutorCourseReferenceItem(course_id);
                this.app.cookieStore.addTutorCourseReferenceItem(course_id, reference);

                //console.log('Update Complete');
            }
        });
    }
    removeTutoringCourse(course_id, reference) {
        let user_id = this.app.cookieStore.getUserData().id;

        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = reference ? courseChildRef.child(reference) : courseChildRef;

        return courseTutorChildRef
                .orderByChild('user_id')
                .equalTo(user_id)
                .once('value', child => {
                    return child.ref.remove((error) => {
                        if (error) {
                            alert('The write failed...');

                            console.log('The write failed... ', error);
                        } else {
                            this.removeCourseReference(course_id);

                            //console.log('Remove Complete');
                        }
                    });
                });
    }

    removeCourseReference(course_id) {
        this.app.cookieStore.removeTutorCourseData(course_id);
        this.app.cookieStore.removeTutorCourseReferenceItem(course_id);

        $("#" + course_id).removeAttr('reference');
    }

    getCourseTutors(course_id) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseTutorsRef = courseRef.child('tutors');

        return courseTutorsRef.once('value');
    }
    checkCourseTutorsStatus(course_id) {
        return this.getCourseTutors(course_id).then((tutors) => {
            let user_id = this.app.cookieStore.getUserData().id;

            let statuses = [];

            if (tutors.val()) {
                // need to add logic here if the tutor is full
                // and there is another possible active not full tutor
                for (const [reference, tutor] of Object.entries(tutors.val())) {
                    if (tutor.status > 0) {
                        let courseTutorStatus = new CourseTutorStatus({
                            url: tutor.url,
                            status: 1,
                            reference: reference,
                            queue_reference: null
                        });
                        if (tutor.queue) {
                            // check if user is in queue with active entry
                            for (const [reference, queue] of Object.entries(tutor.queue)) {
                                if (user_id === queue.user_id) {
                                    courseTutorStatus.queue_reference = reference;
                                    if (queue.status === 3) {
                                        courseTutorStatus.status = 5;
                                    } else if (queue.status === 2) {
                                        courseTutorStatus.status = 4;
                                    } else if (queue.status === 1) {
                                        courseTutorStatus.status = 3;
                                    }

                                    break;
                                }
                            }

                            // if user is not, then check if active entries in
                            // queue are within capacity
                            if (courseTutorStatus.status != 3 && courseTutorStatus.status != 4 && courseTutorStatus.status != 5) {
                                let activeQueueLength = 0;

                                for (const [reference, queue] of Object.entries(tutor.queue)) {
                                    if (queue.status > 0) {
                                        ++activeQueueLength
                                    }
                                }

                                if (activeQueueLength >= tutor.capacity) {
                                    courseTutorStatus.status = 2;
                                } else {
                                    courseTutorStatus.status = 1;
                                }
                            }
                        }

                        statuses.push(courseTutorStatus);
                    }
                }
            }

            if (statuses.length < 1) {
                statuses.push(new CourseTutorStatus({
                    reference: null,
                    queue_reference: null
                }));
            }

            let key = 0;

            //TODO: Get random tutor, need to change based on inner status
            for (let i = statuses.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [statuses[i], statuses[j]] = [statuses[j], statuses[i]];
            }

            let status = statuses[key].status || 0,
                    url = statuses[key].url || null,
                    tutor_reference = statuses[key].reference || null,
                    queue_reference = statuses[key].queue_reference || null;

            this.app.updateStudentCourseStatus(course_id, tutor_reference, status, url, queue_reference);
        });
    }
    subscribeToNewTutorObject(course_id) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseTutorsRef = courseRef.child('tutors');

        return courseTutorsRef.on('child_added', (added) => {
            //console.log('child_added');

            return this.checkCourseTutorsStatus(course_id);
        });
    }
    subscribeToTutorObjectUpdates(course_id) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseTutorsRef = courseRef.child('tutors');

        return courseTutorsRef.on('child_changed', (changed) => {
            //console.log('child_changed');

            return this.checkCourseTutorsStatus(course_id);
        });
    }
    subscribeToTutorObjectRemoval(course_id) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseTutorsRef = courseRef.child('tutors');

        return courseTutorsRef.on('child_removed', (removed) => {
            //console.log('child_removed');

            return this.checkCourseTutorsStatus(course_id);
        });
    }

    addCourseTutoringQueueItem(course_id, reference, status) {
        let user_id = this.app.cookieStore.getUserData().id;

        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseTutorsRef = courseRef.child('tutors'),
                courseTutorRef = courseTutorsRef.child(reference),
                courseTutorQueueRef = courseTutorRef.child('queue');

        return courseTutorQueueRef
                .orderByChild("user_id")
                .equalTo(user_id)
                .once("value", child => {
                    if (child.exists()) {
                        console.log('i exists already');
                    } else {
                        let newChildRef = courseTutorQueueRef.push();

                        newChildRef
                                .set(new CourseTutorQueue(this.app, {status: status}))
                                .then(() => {
                                    //console.log('Insert Complete - Queue');
                                }, function (reason) {
                                    alert('The search failed...');

                                    console.log('The search failed... ', reason);
                                });
                    }
                }, function (reason) {
                    alert('The search failed...');

                    console.log('The search failed... ', reason);
                });
    }

    getTutoringQueueObject(course_id, reference) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(reference);

        return courseTutorChildRef.once('value');
    }
    subscribeToTutoringObjectUpdates(course_id, reference) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(reference);

        return courseTutorChildRef.on('child_changed', (changed) => {
            this.getTutoringQueueObject(course_id, reference).then((room) => {
                this.app.buildTutoringQueueControl(room)
            });
        });
    }
    subscribeToTutoringQueueObjectAdd(course_id, reference) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(reference),
                courseTutorQueueChildRef = courseTutorChildRef.child('queue');

        return courseTutorQueueChildRef.on('child_added', (added) => {
            if (!document.getElementById(added.key)) {
                if (added.val().status === 2 || added.val().status === 3) {
                    this.getTutoringQueueObject(course_id, reference).then((room) => {
                        this.app.buildTutoringQueueControl(room)
                    });
                }
            }
        });
    }
    subscribeToTutoringQueueObjectRemoval(course_id, reference) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(reference),
                courseTutorQueueChildRef = courseTutorChildRef.child('queue');

        return courseTutorQueueChildRef.on('child_removed', (removed) => {
            this.getTutoringQueueObject(course_id, reference).then((room) => {
                this.app.buildTutoringQueueControl(room)
            });
        });
    }

    removeTutoringQueueObject(course_id, tutor_reference, queue_reference) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(tutor_reference),
                courseTutorQueueChildRef = courseTutorChildRef.child('queue'),
                courseTutorQueueItemChildRef = courseTutorQueueChildRef.child(queue_reference);

        return courseTutorQueueItemChildRef
                .remove((error) => {
                    if (error) {
                        alert('The write failed...');

                        console.log('The write failed... ', error);
                    } else {
                        if (this.app.queueClickTimer) {
                            clearTimeout(this.app.queueClickTimer);
                        }

                        if (this.app.queueRoomClickTimers && this.app.queueRoomClickTimers[queue_reference]) {
                            clearTimeout(this.app.queueRoomClickTimers[queue_reference]);
                        }

                        //console.log('Remove Tutring Queue Item Complete');
                    }
                });
    }
    updateTutoringQueueObject(course_id, tutor_reference, queue_reference, status) {
        let coursesRef = this.realtime.ref(this.course_reference_name),
                courseRef = coursesRef.child(course_id),
                courseChildRef = courseRef.child('tutors'),
                courseTutorChildRef = courseChildRef.child(tutor_reference),
                courseTutorQueueChildRef = courseTutorChildRef.child('queue'),
                courseTutorQueueItemChildRef = courseTutorQueueChildRef.child(queue_reference);

        return courseTutorQueueItemChildRef.update({
            status: status
        }, (error) => {
            if (error) {
                alert('The write failed...');

                console.log('The write failed... ', error);
            } else {
                //console.log('Updating Tutring Queue Item Complete');
            }
        });
    }

    initializeRoomApp(room_url) {
        let room_parts = this.app.getRoomPartsFromUrl(room_url);

        let databaseURL = goboardDatabaseHelper.getDatabaseForPrefix(room_parts.prefix),
                appName = goboardDatabaseHelper.getAppNameForRoom(room_parts.name);

        appName = appName === '[DEFAULT]' ? 'goboard' : appName;

        let initializeApp = true;

        firebase.apps.forEach(app => {
            if (app.name === appName) {
                initializeApp = app;
            }
        });

        if (initializeApp === true) {
            initializeApp = firebase.initializeApp(new FirebaseConfig(databaseURL), appName);
        }

        return initializeApp;
    }
    setDropinRoomObject(course_reference, tutor_reference, room_url) {
        let user_id = this.app.cookieStore.getUserData().id;

        let room_parts = this.app.getRoomPartsFromUrl(room_url),
                firebase_room = this.initializeRoomApp(room_url);

        let dropinsRef = firebase_room.database().ref('dropins'),
                dropinsRoomRef = dropinsRef.child(room_parts.name);

        return dropinsRoomRef
                .orderByChild("user_id")
                .equalTo(user_id)
                .once("value", child => {
                    if (child.exists()) {
                        child.forEach((childSnapshot) => {
                            let childKey = childSnapshot.key,
                                    childData = childSnapshot.val();

                            if (childData.user_id === user_id) {
                                $('.my-courses #' + course_reference).data('dropin-reference', childKey);
                            }
                        });
                    } else {
                        return this.addDropinRoomObject(course_reference, tutor_reference, room_url);
                    }
                }, function (reason) {
                    alert('The dropin search failed...');

                    console.log('The dropin search failed... ', reason);
                });
    }
    addDropinRoomObject(course_reference, tutor_reference, room_url) {
        let room_parts = this.app.getRoomPartsFromUrl(room_url),
                firebase_room = this.initializeRoomApp(room_url);

        let dropinsRef = firebase_room.database().ref('dropins'),
                dropinsRoomRef = dropinsRef.child(room_parts.name);

        let newChild = dropinsRoomRef.push();

        return newChild.set(new DropInRoonObject(this.app, {
            course_reference: course_reference,
            tutor_reference: tutor_reference
        })).then(() => {
            //console.log('Insert Room Complete');

            $('.my-courses #' + course_reference).data('dropin-reference', newChild.key);
        }, function (reason) {
            console.log('The insert failed... ', reason);
        });
    }
    removeDropinRoomObject(dropin_reference, room_url) {
        let room_parts = this.app.getRoomPartsFromUrl(room_url),
                firebase_room = this.initializeRoomApp(room_url);

        let dropinsRef = firebase_room.database().ref('dropins'),
                dropinsRoomRef = dropinsRef.child(room_parts.name),
                dropinsRoomChildRef = dropinsRoomRef.child(dropin_reference);

        return dropinsRoomChildRef
                .remove((error) => {
                    if (error) {
                        alert('The write failed...');

                        console.log('The write failed... ', error);
                    } else {
                        //console.log('Remove Dropin Item Complete');
                    }
                });
    }

}
