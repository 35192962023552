class EventSaveStatus {
    constructor(app, data) {
        this.eventType = 'Save';
        this.createdAt = app.databaseStore.firebase.firestore.FieldValue.serverTimestamp();

        this.meta = {
            action: data.action,
            courseId: data.courseId,
            courseTutorRef: data.courseTutorRef || null,
            courseTutor: data.courseTutor || null
        };

        this.session = {
            userID: app.cookieStore.getUserID(),
            userData: app.cookieStore.getUserData(),
            userAgent: navigator.userAgent,
            studentCourseData: app.cookieStore.getStudentCourseData() || null,
            tutorCourseData: app.cookieStore.getTutorCourseData() || null,
            tutorCourseReferenceData: app.cookieStore.getTutorCourseReferenceData() || null
        };
    }
}

class EventJoin {
    constructor(app, data) {
        this.eventType = 'Join';
        this.createdAt = app.databaseStore.firebase.firestore.FieldValue.serverTimestamp();

        this.meta = {
            action: data.action,
            courseId: data.courseId,
            courseTutorRef: data.courseTutorRef || null,
            courseTutorQueueRef: data.courseTutorQueueRef || null,
            roomUrl: data.roomUrl || null
        };

        this.session = {
            userID: app.cookieStore.getUserID(),
            userData: app.cookieStore.getUserData(),
            userAgent: navigator.userAgent,
            studentCourseData: app.cookieStore.getStudentCourseData() || null,
            tutorCourseData: app.cookieStore.getTutorCourseData() || null,
            tutorCourseReferenceData: app.cookieStore.getTutorCourseReferenceData() || null
        };
    }
}

class Logging {
    constructor(app) {
        this.app = app;
    }

    sendEvent(payload) {
        const firestore = this.app.databaseStore.firestore;
        const ref = firestore.collection('dropin-logs').doc();

        ref.set(
                Object.assign({}, payload)
                )
                .then(() => {
                    //console.log('Done')
                })
                .catch(error => {
                    console.error(error)
                });
    }
}

module.exports = {
    EventSaveStatus,
    EventJoin,
    Logging
};
