var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../tutor/my-status-partial.hbs"),depth0,{"name":"../tutor/my-status-partial.hbs","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "tutor";
},"5":function(container,depth0,helpers,partials,data) {
    return "student";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <h3>\n                My Courses:\n                <span class=\"tooltip-help-wrapper\">\n                    <i data-toggle=\"tooltip\" data-html=\"true\" data-placement=\"bottom\" title=\"These are the courses that you have <b>starred</b> from the <b>&quot;Other Drop-In Courses&quot;</b> section below that you are enrolled in currently. By selecting them and moving them here, you won't have to do this again each time you log in.\" class=\"fas fa-question-circle\"></i>\n                </span>\n            </h3>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"info\">If tutoring is CURRENTLY UNAVAILABLE (grey box), make sure to check the drop-in tutoring schedule <a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"dropinTutoringScheduleUrl") : depth0), depth0))
    + "\" target=\"_blank\">here</a> for available times.</span>\n        <span class=\"info\">If you chose to GET IN LINE (blue box), keep this tab open and you will be notified by a pop-up message.</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <i data-toggle=\"tooltip\" data-html=\"true\" data-placement=\"bottom\" title=\"These are the courses that are covered for drop-in tutoring by my university or college. If you are enrolled in one or more of these courses, <b>click on the star</b> next to the course(s), and it will move to the &quot;My Courses&quot; section.\" class=\"fas fa-question-circle\"></i>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                    <i data-toggle=\"tooltip\" data-html=\"true\" data-placement=\"bottom\" title=\"These are the courses that are covered for drop-in tutoring by my university or college. If you can tutor one or more of these courses, <b>click on the star</b> next to the course(s), and it will move to the &quot;My Courses&quot; section.\" class=\"fas fa-question-circle\"></i>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                <p>As a tutor you have GoBoard rooms that are assigned\n                    to you or your topic. You will toggle yourself\n                    Online when working and Offline when not working and\n                    save.<br>You will star the courses that you provide\n                    tutoring for under the \"Other Drop-In Courses\n                    Covered\" and those will pull up to the top of the\n                    page under \"My Courses\".<br>For each course you will\n                    need to input a GoBoard URL and select the max\n                    number of people you want in each GoBoard.</p>\n                <p>Your supervisor will give you more direction on\n                    whether you have one GoBoard for all students or\n                    different ones for different subjects. They will\n                    also provide you guidance on your room capacity.\n                    Once you have saved your information, please go to\n                    your GoBoard room(s) and start tutoring.\n                    <span style=\"color:red\">Don't forget to come back here to\n                    toggle yourself offline when your shift is over.</span> If\n                    you need more assistance, please contact us at\n                    <a target=\"_blank\" href=\"mailto:help@goboard.com\">Help@GoBoard.com</a>.</p>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>As a student you can \"favorite\" the courses you are\n                    \"Drop-In Courses\" section. This pulls those courses\n                    to the top of your page. You have four choices\n                    available in this queue:</p>\n                <p>Green <span style=\"font-weight: 700;\">\"Join Now\"</span>\n                    button allows you to join a tutor immediately in their\n                    GoBoard room.<br>Blue <span style=\"font-weight: 700;\">\"Get\n                    in Line\"</span> button allows you to get in line for\n                    tutoring, as your tutor is currently tutoring someone else.\n                    <br>Black <span style=\"font-weight: 700;\">\"You're In Line\"</span> button indicates that you\n                    selected to <span style=\"font-weight: 700;\">\"Get in Line\"</span> and are currently waiting\n                    on an available tutor.<br>Grey <span style=\"font-weight: 700;\">\"Currently\n                    Unavailable\"</span> button indicates that tutoring\n                    currently unavailable.<br>for your course and that\n                    you need to check the drop-in tutoring schedule\n                    <a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"dropinTutoringScheduleUrl") : depth0), depth0))
    + "\" target=\"_blank\">here</a>.</p>\n                <p style=\"font-weight: 700;\">Please keep this tab open\n                    in your browser so that when you are notified by\n                    pop-up message you can easily join your tutoring\n                    session.</p>\n                <p>If you need more assistance, please contact us at\n                    <a target=\"_blank\" href=\"mailto:help@goboard.com\">Help@GoBoard.com</a>.</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./header-partial.hbs"),depth0,{"name":"./header-partial.hbs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<main role=\"main\" class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-sm\">\n            <h1 class=\"text-center\">\n                <!-- "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"schoolName") : depth0), depth0))
    + " --> Drop-in tutoring queue\n            </h1>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tutorPage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"my-courses "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tutorPage") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":27},"end":{"line":16,"column":56}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentPage") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":57},"end":{"line":16,"column":90}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentPage") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"entries\">\n\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentPage") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"courses\">\n        <h3>\n            Other Drop-in Courses:\n            <span class=\"tooltip-help-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"studentPage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":44,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n        </h3>\n\n        <div class=\"row entries\">\n\n        </div>\n    </div>\n\n    <div class=\"how-this-works-container\">\n        <h3 data-toggle=\"collapse\" data-target=\"#collapseHowItWorks\" aria-expanded=\"false\" aria-controls=\"collapseHowItWorks\">\n            How This Works\n        </h3>\n\n        <div class=\"collapse\" id=\"collapseHowItWorks\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tutorPage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":59,"column":12},"end":{"line":102,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</main>\n\n"
    + ((stack1 = container.invokePartial(require("./footer-partial.hbs"),depth0,{"name":"./footer-partial.hbs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./modals-partial.hbs"),depth0,{"name":"./modals-partial.hbs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});