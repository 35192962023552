import {app} from './app.js';

export default class Student {
    initializeCourses() {
        let myCourses = app.cookieStore.getStudentCourseData();

        let courseCompTpl = app.getHtmlTemplate('common/course');

        app.courses.forEach((course, i) => {
            course.sort = (+i + 1);

            if ($.inArray(course.id, myCourses) === -1) {
                $(".courses .entries").append(courseCompTpl(course));
            } else {
                course.favorited = true;
                course.addAsRow = true;

                $(".my-courses .entries").append(courseCompTpl(course));
                $('.my-courses .entries [data-toggle="tooltip"]').tooltip();
            }

            this.monitorCourse(course.id);
        });
    }

    monitorCourse(course_id) {
        app.databaseStore.subscribeToNewTutorObject(course_id);
        app.databaseStore.subscribeToTutorObjectUpdates(course_id);
        app.databaseStore.subscribeToTutorObjectRemoval(course_id);
    }

    joinTutoringQueue(course_id, reference) {
        return app.databaseStore.addCourseTutoringQueueItem(course_id, reference, 1);
    }

    joinTutoringSession(course_id, reference) {
        return app.databaseStore.addCourseTutoringQueueItem(course_id, reference, 2);
    }

}
