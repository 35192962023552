var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"tooltip-help-wrapper\">\n            <i data-toggle=\"tooltip\" data-html=\"true\" data-placement=\"bottom\" title=\"Tutoring is available, but the tutor is with someone else right now. <b>You're in line</b> to meet with a tutor! <b>Keep this tab open</b> and a pop-up message will inform of when you can <b>join now</b>!\" class=\"fas fa-question-circle info-tooltip\"></i>\n        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"label status-5\" data-reference=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? lookupProperty(stack1,"reference") : stack1), depth0))
    + "\" data-url=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n    Waiting\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"favorited") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});