var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"slot\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"reference") || (depth0 != null ? lookupProperty(depth0,"reference") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reference","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":55}}}) : helper)))
    + "\">\n                        <span>Slot "
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":44}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":14,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button class=\"end-student-session btn btn-primary\" data-reference=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reference") || (depth0 != null ? lookupProperty(depth0,"reference") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"reference","hash":{},"data":data,"loc":{"start":{"line":9,"column":96},"end":{"line":9,"column":109}}}) : helper)))
    + "\">End Session</button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"waiting") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":14,"column":24}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <span>Waiting</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                            <span>Available</span>\n                        ";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row justify-content-lg-center\">\n            <div class=\"col col-sm-12 col-lg-3 text-center no-pad\">\n                <button class=\"btn btn-primary btn-block invite-student\" data-reference=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nextLineReference") || (depth0 != null ? lookupProperty(depth0,"nextLineReference") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nextLineReference","hash":{},"data":data,"loc":{"start":{"line":30,"column":89},"end":{"line":30,"column":110}}}) : helper)))
    + "\">Invite Next Student</button>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container wrapper-room\">\n    <div class=\"row justify-content-lg-center\">\n        <div class=\"col col-sm-12 col-lg-3 no-pad\">\n            <div class=\"slots\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"slots") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n    <div class=\"row justify-content-lg-center\">\n        <div class=\"col col-sm-12 col-lg-4 text-center no-pad\">\n            <div id=\"queue-count\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"queueCount") || (depth0 != null ? lookupProperty(depth0,"queueCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"queueCount","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":30}}}) : helper)))
    + "</span> student<span id=\"queue-suffix\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"queueSuffix") || (depth0 != null ? lookupProperty(depth0,"queueSuffix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"queueSuffix","hash":{},"data":data,"loc":{"start":{"line":23,"column":69},"end":{"line":23,"column":84}}}) : helper)))
    + "</span> waiting in line.\n            </div>\n        </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNextButton") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});