import {app} from './app.js';
import {EventSaveStatus} from './common/log-helper.js';

class CourseTutor {
    constructor(course) {
        this.uuid = app.cookieStore.getUserID();
        this.user_id = app.cookieStore.getUserData().id;
        this.status = parseInt(course.status, 10) || 0;
        this.url = course.url || '';
        this.capacity = parseInt(course.capacity, 10) || 1;
        this.queue = [];
        this.timestamp = app.databaseStore.firebase.database.ServerValue.TIMESTAMP;
    }
}

export default class TutorSettings {

    constructor() {
        this.unsavedChanges = false;

        this.saveButton = $('#save-status-button');
    }

    uiChanged() {
        this.saveButton.removeAttr('disabled');
        this.unsavedChanges = true;
    }

    save() {
        this.saveButton.html('<i class="fas fa-spinner fa-spin"></i>');

        $('input, select', '.course-settings').attr('disabled', 'disabled');

        let my_courses = app.cookieStore.getTutorCourseData();

        let valid = true;

        $(".my-courses .course").each((i, that) => {
            let course_id = that.id;

            let parent = $('.my-courses #' + course_id);

            my_courses.push(course_id);

            if (parent.length) {
                let parentURL = $('.course-room-url', parent);
                let urlVal = parentURL.val();

                if (app.schoolhelper.isHostFromUniversityOfMichigan()) {
                    let parentUrlPrefix = $('.course-room-url-prefix', parent);
                    let roomNumberVal = parentURL.val();

                    if (!(/^[A-z0-9]{6}$/.test(roomNumberVal.trim()))) {
                        alert('Please enter a valid Goboard Room Number for ' + app.getCourseNameByID(course_id));

                        valid = false;
                    }

                    urlVal = parentUrlPrefix.text() + roomNumberVal;
                }

                if (valid && parentURL.data('valid') != true || urlVal == 'http://goboard.com/' || urlVal == 'http://goboard.com' || urlVal == 'https://goboard.com/' || urlVal == 'https://goboard.com') {
                    alert('Please enter a valid Goboard Room URL for ' + app.getCourseNameByID(course_id));

                    valid = false;
                }

                if (valid && urlVal.substr(0, 7) !== 'http://' && urlVal.substr(0, 8) !== 'https://') {
                    alert('Please enter a valid Goboard Room URL for ' + app.getCourseNameByID(course_id));

                    valid = false;
                }

                if (valid === true) {
                    let parentCapacity = $('.course-room-capacity', parent);

                    if (!(parentCapacity.val() > 0)) {
                        alert('Please select a GoBoard room capacity for ' + app.getCourseNameByID(course_id));

                        valid = false;
                    }
                }
            }
        });

        if (valid === true) {
            my_courses = [...new Set(my_courses)];

            my_courses.forEach(function (course_id) {
                let parent = $('.my-courses #' + course_id);

                if (parent.length) {
                    let url = $('.course-room-url', parent).val()

                    if (app.schoolhelper.isHostFromUniversityOfMichigan()) {
                        let urlPrefix = $('.course-room-url-prefix', parent).text();

                        url = urlPrefix + url;
                    }

                    let course_tutor = new CourseTutor({
                        url: url,
                        capacity: $('.my-courses #' + course_id + ' .course-room-capacity').val(),
                        status: $('#customSwitch1').is(':checked') ? 1 : 0
                    })

                    app.databaseStore.saveTutoringCourse(course_id, course_tutor).then(() => {
                        let reference;

                        app.cookieStore.getTutorCourseReferenceData().forEach((data, i) => {
                            if (data.id === course_id) {
                                reference = data.reference || null;
                            }
                        });

                        $("#" + course_id).data('reference', reference);


                        app.removeFavoriteCourse(course_id);
                        app.addFavoriteCourse(course_id);

                        app.logEvent(new EventSaveStatus(app, {
                            action: 'Add',
                            courseId: course_id,
                            courseTutorRef: reference,
                            courseTutor: {
                                url: course_tutor.url,
                                capacity: course_tutor.capacity,
                                status: course_tutor.status
                            }
                        }));

                        if (course_tutor.status === 1) {
                            let course_reference = course_id,
                                    tutor_reference = reference;

                            app.databaseStore.setDropinRoomObject(course_reference, tutor_reference, course_tutor.url);
                        } else {
                            let dropin_reference = parent.data('dropin-reference');

                            if (dropin_reference) {
                                app.databaseStore.removeDropinRoomObject(dropin_reference, course_tutor.url);
                            }
                        }
                    });
                } else {
                    // if data stays in local storage but is removed from web
                    // we will get this condition, we need to remove it from local
                    // TODO: address if not found in removed_refs, and add it to
                    // it so that the below code can generically remove it. the
                    // below code is for when the tutor removes it from the their list
                    //alert('check condition 2');
                    console.log('TODO: Check this condition 1');

                    app.removed_references.forEach(function (data, i) {
                        if (data.id === course_id) {
                            app.databaseStore.removeTutoringCourse(course_id, data.reference).then(() => {
                                app.logEvent(new EventSaveStatus(app, {
                                    action: 'Remove',
                                    courseId: course_id,
                                    courseTutorRef: data.reference
                                }));

                                if (data.dropin_reference && data.url) {
                                    app.databaseStore.removeDropinRoomObject(data.dropin_reference, data.url);
                                }

                                // ui is already addressed by the save.
                                //alert('check condition here 2');
                                console.log('TODO: Check this condition 2');
                            });
                        }
                    });
                }
            });

            this.saveButton.attr('disabled', 'disabled');
            this.unsavedChanges = false;
        }

        app.removed_references = [];

        this.saveButton.html('save');

        $('input, select', '.course-settings').removeAttr('disabled');
    }
}
