var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tutorPage") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"userData") : depth0)) != null ? lookupProperty(stack1,"canvas_roles") : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":18,"column":31}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locationOrigin") || (depth0 != null ? lookupProperty(depth0,"locationOrigin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locationOrigin","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":51}}}) : helper)))
    + "/\">Student View</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"userData") : depth0)) != null ? lookupProperty(stack1,"is_tutor") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":14,"column":31}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"locationOrigin") || (depth0 != null ? lookupProperty(depth0,"locationOrigin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locationOrigin","hash":{},"data":data,"loc":{"start":{"line":13,"column":37},"end":{"line":13,"column":55}}}) : helper)))
    + "/#/tutor\">Tutor View</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"https://goboard.com/\" id=\"logout\">logout</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer>\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-lg-6 col-sm-6\">\n                &copy; 2020 Study Edge. All Rights Reserved.\n            </div>\n            <div class=\"col-lg-6 col-sm-6 text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "                <a href=\"https://goboard.com/privacy#tab-1\" target=\"_blank\">Terms of Use</a>\n                <a href=\"https://goboard.com/privacy\" target=\"_blank\">Privacy Policy</a>\n            </div>\n        </div>\n    </div>\n</footer>\n";
},"useData":true});