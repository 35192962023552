import 'bootstrap';
import '../sass/main.scss';
import {parseDomain, fromUrl} from "parse-domain";
import Handlebars from 'handlebars';

import Authentication from './authentication.js';
import CookieStore from './common/cookie-store.js';
import DatabaseStore from './common/database-store.js';
import {Logging} from './common/log-helper.js';
import Tutor from './tutor.js';
import Student from './student.js';

import './common/statics-helper.js';
import {urlhelper} from './common/url-helper.js';
import {schoolhelper} from './common/school-helper.js';

class App {
    constructor() {
        this.authentication = new Authentication();

        if (this.authentication.isLoggedIn() !== true) {
            return false;
        }

        this.handlebars = Handlebars;
        this.urlhelper = urlhelper;
        this.schoolhelper = schoolhelper;
        this.cookieStore = new CookieStore();
        this.databaseStore = new DatabaseStore(this);
        this.logging = new Logging(this);

        this.courses = [];
        this.removed_references = [];
        this.queueWaitTime = process.env.NODE_ENV === "production" ? 180000 : 30000;

        if (this.urlhelper.isRoomPage()) {
            let hash_parts = window.location.hash.split('/');

            this.room = {
                course_id: hash_parts[2],
                reference: hash_parts[3]
            };
        } else {
            this.loadPageTemplate();
        }
    }

    loadPageTemplate() {
        let indexCompTpl = this.getHtmlTemplate('common/index');

        let params = {
            schoolName: this.schoolhelper.getSchoolName(),
            dropinTutoringScheduleUrl: this.schoolhelper.getDropinTutoringScheduleUrl(),
            studentPage: this.urlhelper.isStudentPage(),
            tutorPage: this.urlhelper.isTutorPage(),
            isLoggedIn: this.authentication.isLoggedIn(),
            userData: this.authentication.getUserData(),
            locationOrigin: window.location.origin
        };

        $('#page-container').html(indexCompTpl(params)).show();
    }

    getCourseNameByID(course_id) {
        let course_name = null;

        for (let i = 0; i < this.courses.length; i++) {
            if (this.courses[i].id === course_id) {
                course_name = this.courses[i].name;

                break;
            }
        }

        return course_name;
    }

    getCourseByID(course_id) {
        let course = null;

        for (let i = 0; i < this.courses.length; i++) {
            if (this.courses[i].id === course_id) {
                course = this.courses[i];

                break;
            }
        }

        return course;
    }

    initializeCourses() {
        if (this.authentication.isLoggedIn() !== true) {
            return false;
        }

        if (this.courses.length > 0) {
            return false;
        }

        let whereValue = schoolhelper.isHostFromUniversityOfMichigan() ? 'umich' : null;

        this.databaseStore
                .firestore
                .collection("dropin-courses")
                .where('school_prefix', '==', whereValue)
                .orderBy('name')
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.courses.push({
                            id: doc.id,
                            name: doc.data().name
                        });
                    });
                }).catch((error) => {
            console.error(error);
        }).finally(() => {
            const tutor = new Tutor();
            const student = new Student();

            if (app.urlhelper.isRoomPage()) {
                tutor.initializeQueueControl(app.room.course_id, app.room.reference);
            } else {
                if (app.urlhelper.isTutorPage()) {
                    tutor.initializeCourses();
                    tutor.initializeStatus();
                } else {
                    student.initializeCourses();
                }
            }
        });
    }

    addFavoriteCourse(course_id) {
        if (this.urlhelper.isTutorPage()) {
            this.cookieStore.addTutorCourseData(course_id);
        } else {
            this.cookieStore.addStudentCourseData(course_id);
        }
    }

    removeFavoriteCourse(course_id) {
        if (this.urlhelper.isTutorPage()) {
            this.cookieStore.removeTutorCourseData(course_id);
        } else {
            this.cookieStore.removeStudentCourseData(course_id);
        }
    }

    updateStudentCourseStatus(course_id, tutor_reference, status, _url, queue_reference) {
        let url = _url + '?' + app.getGoBoardLoginUrlParams();

        let parent = $('#' + course_id),
                params = {
                    label: {
                        [`status${status || 0}`]: true,
                        url: url,
                        reference: tutor_reference, // to migrate to tutor_reference
                        tutor_reference: tutor_reference,
                        queue_reference: queue_reference
                    }
                };

        if (parent.parents('.my-courses').length) {
            params.favorited = true;
        }

        let labelCompTpl = this.getHtmlTemplate('student/label-partial'),
                html = labelCompTpl(params);

        $('.label', parent).replaceWith(html);
        $('[data-toggle="tooltip"]', parent).tooltip();

        if (status === 5) {
            this.queueClickTimer;

            $('#join-room-modal a.room-url')
                    .attr('href', url)
                    .data({
                        'course-id': course_id,
                        'tutor-reference': tutor_reference,
                        'queue-reference': queue_reference
                    });

            $('#join-room-modal span.course-name').text(this.getCourseNameByID(course_id));

            $('#join-room-modal').modal('show').on('shown.bs.modal', function () {
                app.queueClickTimer = setTimeout(function () {
                    app.databaseStore.removeTutoringQueueObject(course_id, tutor_reference, queue_reference).then(data => {
                        $('#join-room-modal').modal('hide');
                    });
                }, app.queueWaitTime);
            });
        }
    }

    buildTutoringQueueControl(room) {
        let roomIndexCompTpl = this.getHtmlTemplate('room/index');

        let line = [],
                data = {
                    capacity: room.val().capacity,
                    queueCount: 0,
                    queueSuffix: 's',
                    nextLineReference: null,
                    slots: []
                };

        let objectQueue = room.exists() && room.val().queue ? Object.entries(room.val().queue) : [];

        if (objectQueue.length > data.capacity) {
            let objectQueueIndex = 0;

            for (const [reference, student] of objectQueue) {
                if (student.status === 2 || student.status === 3) {
                    data.slots.push({
                        index: objectQueueIndex + 1,
                        reference: reference,
                        active: student.status === 2 ? true : false,
                        waiting: student.status === 3 ? true : false,
                        ...student
                    });
                } else {
                    data.queueCount++;

                    if (data.slots.length < data.capacity) {
                        data.showNextButton = true;

                        data.slots.push({
                            index: objectQueueIndex + 1,
                            active: false,
                            waiting: false
                        });

                        line.push({
                            reference: reference,
                            timestamp: student.timestamp
                        });
                    }
                }

                objectQueueIndex++;
            }
        } else {
            [...Array(data.capacity).keys()].map(i => {
                let queueItem = objectQueue[i];

                if (queueItem) {
                    let reference = queueItem[0],
                            student = queueItem[1];

                    if (student.status === 2 || student.status === 3) {
                        data.slots.push({
                            index: i + 1,
                            reference: reference,
                            active: student.status === 2 ? true : false,
                            waiting: student.status === 3 ? true : false,
                            ...student
                        });
                    } else {
                        data.queueCount++;

                        if (data.slots.length < data.capacity) {
                            data.showNextButton = true;

                            data.slots.push({
                                index: i + 1,
                                active: false,
                                waiting: false
                            });

                            line.push({
                                reference: reference,
                                timestamp: student.timestamp
                            });
                        }
                    }
                } else {
                    data.showNextButton = data.queueCount > 0 ? true : false;

                    data.slots.push({
                        index: i + 1,
                        active: false,
                        waiting: false
                    });
                }
            });
        }

        if (data.queueCount === 1) {
            data.queueSuffix = '';
        }

        let slot_index = 1;
        data.slots.forEach((slot, i) => {
            slot.index = slot_index;
            slot_index++;
        });

        line.sort((a, b) => {
            if (a.timestamp < b.timestamp) {
                return -1;
            }

            if (a.timestamp > b.timestamp) {
                return 1;
            }

            return 0;
        });

        if (line.length) {
            data.nextLineReference = line[0].reference;
        }

        $("#page-container").html(roomIndexCompTpl(data)).show();
    }

    getHtmlTemplate(path) {
        return require("../templates/" + path + ".hbs");
    }

    getRoomPartsFromUrl(room_url) {
        let url = new URL(room_url);
        let {subDomains} = parseDomain(fromUrl(room_url));
        let room_prefix = subDomains[0],
                room_id = url.pathname.substring(1),
                name = room_prefix + '_' + room_id;

        return {
            prefix: room_prefix,
            id: room_id,
            name: name
        };
    }

    getGoBoardLoginUrlParams() {
        let userData = this.authentication.getUserData();

        let params = {
            email: userData.email_address,
            first_name: userData.first_name,
            last_name: userData.last_name
        };

        if (userData.is_tutor > 0) {
            params.user_id = userData.id;
        }

        let searchParams = Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');

        return searchParams;
    }

    logout() {
        this.authentication.logout();
    }

    logEvent(event) {
        if (process.env.NODE_ENV === "production") {
            return this.logging.sendEvent(event);
        }
    }

}

export const app = new App();
