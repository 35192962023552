import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import Cookies from 'js-cookie';

import {urlhelper} from './url-helper.js';

export default class CookieStore {
    /*
     * _uuid - unique user id for "sessions"
     * ud - holds authenticated user data
     * sc - holds courses a student adds to favorites
     * tc - holds courses a tutor adds to favorites
     * rc - holds courses a student is subscribed to
     */
    static get NAME_UUID() {
        return '_uuid';
    }
    static get NAME_USER_DATA() {
        return 'ud';
    }
    static get NAME_STUDENT_COURSES() {
        return 'sc';
    }
    static get NAME_TUTOR_COURSES() {
        return 'tc';
    }
    static get NAME_TUTOR_COURSE_REFERENCES() {
        return 'tcr';
    }

    constructor() {
        this.setParams = {
            sameSite: 'None',
            secure: true
        };

        this.cookies = Cookies.withConverter({
            read: (value, name) => {
                if ([
                    CookieStore.NAME_USER_DATA,
                    CookieStore.NAME_STUDENT_COURSES,
                    CookieStore.NAME_TUTOR_COURSES,
                    CookieStore.NAME_TUTOR_COURSE_REFERENCES
                ].includes(name)) {
                    return atob(value);
                }

                return value;
            },
            write: (value, name) => {
                if ([
                    CookieStore.NAME_USER_DATA,
                    CookieStore.NAME_STUDENT_COURSES,
                    CookieStore.NAME_TUTOR_COURSES,
                    CookieStore.NAME_TUTOR_COURSE_REFERENCES
                ].includes(name)) {
                    return btoa(value);
                }

                return value;
            }
        });
    }

    getUserID() {
        if (uuidValidate(this.cookies.get(CookieStore.NAME_UUID)) === false) {
            this.cookies.set(CookieStore.NAME_UUID, uuidv4(), this.setParams);
        }

        return this.cookies.get(CookieStore.NAME_UUID);
    }
    setUserID() {
        // TODO: Cookie expiration
        if (uuidValidate(this.getUserID()) === false) {
            this.cookies.set(CookieStore.NAME_UUID, uuidv4(), {
                ...this.setParams,
                domain: '.' + urlhelper.getDomain(window.location.hostname),
            });
        }
    }
    removeUserID() {
        this.cookies.remove(CookieStore.NAME_UUID)
    }

    getUserData() {
        if (typeof this.cookies.getJSON(CookieStore.NAME_USER_DATA) === 'undefined') {
            return false;
        }

        return this.cookies.getJSON(CookieStore.NAME_USER_DATA);
    }
    setUserData(user_data) {
        if (!user_data) {
            return false;
        }

        // TODO: Cookie expiration
        this.cookies.set(CookieStore.NAME_USER_DATA, user_data, this.setParams);
    }
    clearUserData() {
        this.cookies.remove(CookieStore.NAME_USER_DATA)
    }

    getStudentCourseData() {
        if (typeof this.cookies.getJSON(CookieStore.NAME_STUDENT_COURSES) === 'undefined') {
            this.cookies.set(CookieStore.NAME_STUDENT_COURSES, [], this.setParams);
        }

        return this.cookies.getJSON(CookieStore.NAME_STUDENT_COURSES);
    }
    addStudentCourseData(course_id) {
        if (!course_id) {
            return false;
        }

        let skip = false,
                data = this.getStudentCourseData();

        data.forEach(function (id, i) {
            if (id === course_id) {
                skip = true;
            }
        });

        if (skip === false) {
            data.push(course_id);
        }

        this.cookies.set(CookieStore.NAME_STUDENT_COURSES, data, this.setParams);
    }
    removeStudentCourseData(course_id) {
        if (!course_id) {
            return false;
        }

        let data = this.getStudentCourseData(),
                id_index = data.map(function (id) {
                    return id;
                }).indexOf(course_id);

        if (id_index >= 0) {
            data.splice(id_index, 1);
        }

        this.cookies.set(CookieStore.NAME_STUDENT_COURSES, data, this.setParams);
    }

    getTutorCourseData() {
        if (typeof this.cookies.getJSON(CookieStore.NAME_TUTOR_COURSES) === 'undefined') {
            this.cookies.set(CookieStore.NAME_TUTOR_COURSES, [], this.setParams);
        }

        return this.cookies.getJSON(CookieStore.NAME_TUTOR_COURSES);
    }
    addTutorCourseData(course_id) {
        if (!course_id) {
            return false;
        }

        let skip = false,
                data = this.getTutorCourseData();

        data.forEach(function (id, i) {
            if (id === course_id) {
                skip = true;
            }
        });

        if (skip === false) {
            data.push(course_id);
        }

        this.cookies.set(CookieStore.NAME_TUTOR_COURSES, data, this.setParams);
    }
    removeTutorCourseData(course_id) {
        if (!course_id) {
            return false;
        }

        let data = this.getTutorCourseData(),
                id_index = data.map(function (id) {
                    return id;
                }).indexOf(course_id);

        if (id_index >= 0) {
            data.splice(id_index, 1);
        }

        this.cookies.set(CookieStore.NAME_TUTOR_COURSES, data, this.setParams);
    }

    getTutorCourseReferenceData() {
        if (typeof this.cookies.getJSON(CookieStore.NAME_TUTOR_COURSE_REFERENCES) === 'undefined') {
            this.cookies.set(CookieStore.NAME_TUTOR_COURSE_REFERENCES, [], this.setParams);
        }

        return this.cookies.getJSON(CookieStore.NAME_TUTOR_COURSE_REFERENCES);
    }
    addTutorCourseReferenceItem(course_id, reference) {
        if (!course_id || !reference) {
            return false;
        }

        let skip = false,
                data = this.getTutorCourseReferenceData();

        data.forEach(function (node, i) {
            if (node.id === course_id && node.reference === reference) {
                skip = true;
            } else if (node.id === course_id && node.reference !== reference) {
                data.splice(i, 1);
            }
        });

        if (skip === false) {
            data.push({
                id: course_id,
                reference: reference
            });
        }

        this.cookies.set(CookieStore.NAME_TUTOR_COURSE_REFERENCES, data, this.setParams);
    }
    removeTutorCourseReferenceItem(course_id) {
        if (!course_id) {
            return false;
        }

        let data = this.getTutorCourseReferenceData(),
                id_index = data.map(function (e) {
                    return e.id;
                }).indexOf(course_id);

        if (id_index >= 0) {
            data.splice(id_index, 1);
        }

        this.cookies.set(CookieStore.NAME_TUTOR_COURSE_REFERENCES, data, this.setParams);
    }

    clearApp() {
        this.removeUserID();
        this.clearUserData();
        this.removeTutorCourseData();
        this.removeTutorCourseReferenceItem();
    }

}
