import {app} from './app.js';
export default class Tutor {
    initializeCourses() {
        let myCourses = app.cookieStore.getTutorCourseData();

        let courseCompTpl = app.getHtmlTemplate('common/course'),
                myCourseCompTpl = app.getHtmlTemplate('tutor/my-course');

        app.courses.forEach((course, i) => {
            course.sort = (+i + 1);
            course.tutorPage = true;

            app.cookieStore.getTutorCourseReferenceData().forEach((data, i) => {
                if (data.id === course.id) {
                    course.reference = data.reference || null;
                }
            });

            if ($.inArray(course.id, myCourses) === -1) {
                $(".courses .entries").append(courseCompTpl(course));
            } else {
                app.databaseStore.getTutoringCourse(course.id, course.reference)
                        .then((data) => {
                            if (data.exists()) {
                                course.url = data.val().url;
                                course['capacity' + (data.val().capacity + 0)] = true;
                            } else {
                                course.reference = null;
                                course.url = '';

                                app.databaseStore.removeTutoringCourse(course.id).then(() => {
                                    $("#" + course.id).parents('.course-entry').remove();

                                    $(".courses .entries").append(courseCompTpl(course));
                                });
                            }
                        })
                        .finally(() => {
                            let url = course.url || '';

                            course.favorited = true;

                            if (course.url && app.schoolhelper.isHostFromUniversityOfMichigan()) {
                                let parts = app.getRoomPartsFromUrl(course.url);

                                course.prefix = parts.prefix;
                                course.url_input = parts.id;
                            } else {
                                course.url_input = course.url;
                            }

                            $(".my-courses .entries").append(myCourseCompTpl(course));
                            $('.my-courses .entries [data-toggle="tooltip"]').tooltip();

                            if (app.urlhelper.isValidURL(url)) {
                                if (app.urlhelper.getDomain(url) == 'goboard.com') {
                                    $(".course-room-url", ".course#" + course.id).data('valid', true);
                                }
                            }

                            if ($('#customSwitch1').is(':checked')) {
                                app.databaseStore.setDropinRoomObject(course.id, course.reference, course.url);
                            }
                        });
            }
        });
    }

    initializeStatus() {
        app.databaseStore.getTutoringStatuses().then((courses) => {
            let user_id = app.cookieStore.getUserData().id;

            let statuses = [];

            courses.forEach((course) => {
                if (course.val().tutors) {
                    for (const [reference, tutor] of Object.entries(course.val().tutors)) {
                        if (tutor.user_id === user_id) {
                            statuses.push(tutor.status);
                        }
                    }
                }
            });

            if (statuses.length) {
                let uniqueStatuses = [];

                $.each(statuses, function (i, el) {
                    if ($.inArray(el, uniqueStatuses) === -1)
                        uniqueStatuses.push(el);
                });

                if ($.inArray(1, uniqueStatuses) !== -1) {
                    $('#customSwitch1').data('auto-clicked', true);
                    $(".custom-control-label").click();
                }
            }
        });
    }

    initializeQueueControl(course_id, reference) {
        app.databaseStore.getTutoringQueueObject(course_id, reference).then((room) => {
            app.buildTutoringQueueControl(room)

            this.monitorCourseQueueControl(course_id, reference);
        });
    }

    monitorCourseQueueControl(course_id, reference) {
        app.databaseStore.subscribeToTutoringObjectUpdates(course_id, reference);

        app.databaseStore.subscribeToTutoringQueueObjectAdd(course_id, reference);
        app.databaseStore.subscribeToTutoringQueueObjectRemoval(course_id, reference);
    }

    removeTutoringQueueObject(course_id, tutor_reference, queue_reference) {
        return app.databaseStore.removeTutoringQueueObject(course_id, tutor_reference, queue_reference);
    }

    updateTutoringQueueObjectStatus(course_id, tutor_reference, queue_reference, status) {
        return app.databaseStore.updateTutoringQueueObject(course_id, tutor_reference, queue_reference, status);
    }

}
