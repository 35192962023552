class SchoolHelper {
    getGoboardPrefix() {
        var schoolName = 'default';

        return schoolName;
    }

    getSchoolPrefix() {
        var schoolPrefix = null;

        if (this.isHostFromUniversityOfMichigan()) {
            schoolPrefix = 'umich';
        }

        return schoolPrefix;
    }

    getSchoolName() {
        var schoolName = '';

        if (this.isHostFromStaging()) {
            schoolName = 'STAGING';
        } else if (this.isHostFromUniversityOfMichigan()) {
            schoolName = 'University of Michigan';
        }

        return schoolName;
    }

    getDropinTutoringScheduleUrl() {
        var dropinTutoringScheduleUrl = 'https://goboard.com/';

        if (this.isHostFromUniversityOfMichigan()) {
            dropinTutoringScheduleUrl = 'https://lsa.umich.edu/slc/tutoring/drop-in-peer-tutoring.html';
        }

        return dropinTutoringScheduleUrl;
    }

    isHostFromStaging() {
        return window.location.host === 'queuestaging.goboard.com' || window.location.host === 'localhost:8080';
    }

    isHostFromDefault() {
        return window.location.host === 'queue.goboard.com';
    }

    isHostFromUniversityOfMichigan() {
        return window.location.host === 'umich.queue.goboard.com';
    }

}

export const schoolhelper = new SchoolHelper();
