class UrlHelper {
    getTmsUrl(development) {
        if (development === true) {
            return 'https://testing.tutormatchingservice.com';
        }

        return 'https://tutormatchingservice.com';
    }

    getHostName(url) {
        if (url.substr(0, 8) != 'https://') {
            if (url.substr(0, 7) != 'http://') {
                url = 'http://' + url;
            }
        }

        var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);

        if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
            return match[2];
        } else {
            return null;
        }
    }

    getDomain(url) {
        var hostname = this.getHostName(url);
        var domain = hostname;

        if (hostname != null) {
            var parts = hostname.split('.').reverse();

            if (parts != null && parts.length > 1) {
                domain = parts[1] + '.' + parts[0];

                if (hostname.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
                    domain = parts[2] + '.' + domain;
                }
            }
        }

        return domain;
    }

    isValidURL(value) {
        if (value.substr(0, 8) != 'https://') {
            if (value.substr(0, 7) != 'http://') {
                value = 'http://' + value;
            }
        }

        if (value.substr(value.length - 1, 1) != '/') {
            value = value + '/';
        }

        return /^(http|https|ftp):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(value);
    }

    isStudentPage() {
        return window.location.hash === '#/' || window.location.hash === '' ? true : false;
    }

    isTutorPage() {
        return window.location.hash === '#/tutor' ? true : false;
    }

    isRoomPage() {
        return window.location.hash.startsWith("#/room/") ? true : false;
    }

    isSsoRequest() {
        if (window.location.pathname === '/' && window.location.hash === '') {
            if (new URLSearchParams(window.location.search).has('tms-sso')) {
                return true;
            }
        }

        return false;
    }

}

export const urlhelper = new UrlHelper();
