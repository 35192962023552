import {app} from './js/app.js';

import TutorSettings from './js/tutor-settings.js';
import Tutor from './js/tutor.js';
import Student from './js/student.js';

import {EventJoin} from './js/common/log-helper.js';

const tutorSettings = new TutorSettings();
const tutor = new Tutor();
const student = new Student();

window.onhashchange = () => {
    if (app.urlhelper.isStudentPage() || app.urlhelper.isTutorPage()) {
        window.location.reload();
    }
};


$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();

    app.initializeCourses();

    $("body").delegate(".course i:not([data-toggle='tooltip'])", "click", function () {
        let element = $(this);

        var favorite = element.parents('.my-courses').length ? true : false,
                id = element.parents('.course').attr('id'),
                reference = element.parents('.course').data('reference'),
                dropin_reference = element.parents('.course').data('dropin-reference'),
                sort = element.parents('[data-sort]').data('sort'),
                url = element.parents('.course').find('.course-room-url').val(),
                url_input = url,
                prefix;

        if (app.schoolhelper.isHostFromUniversityOfMichigan()) {
            prefix = 'umich';

            if (url) {
                let url_prefix = element.parents('.course').find('.course-room-url-prefix').text();

                url = url_prefix + url_input;
            }
        }

        element.parents('.course-entry').remove();

        let data = {
            id: id,
            name: app.getCourseNameByID(id),
            reference: reference,
            dropin_reference: dropin_reference,
            sort: sort,
            url: url,
            url_input: url_input,
            prefix: prefix,
            label: {},
            tutorPage: app.urlhelper.isTutorPage()
        };

        if (app.urlhelper.isStudentPage()) {
            let _status = $('.label', element.parents('.course-entry')).attr('class').toString().split(' '),
                    status = _status.length > 1 ? parseInt(_status.pop().replace(/\D/g, ""), 10) || 0 : 0;

            data.label['status' + status] = true;
            data.label.url = $('.label', element.parents('.course-entry')).data('url');
            data.label.reference = $('.label', element.parents('.course-entry')).data('reference');
        }

        if (favorite) {
            let courseCompTpl = app.getHtmlTemplate('common/course');

            $(".courses .entries").append(courseCompTpl(data));

            if (app.urlhelper.isStudentPage()) {
                app.removeFavoriteCourse(id);
            }

            if (app.urlhelper.isTutorPage()) {
                tutorSettings.uiChanged();

                app.removed_references.push({
                    id: data.id,
                    reference: data.reference,
                    dropin_reference: data.dropin_reference,
                    url: data.url
                });
            }
        } else {
            data.favorited = true;

            if (app.urlhelper.isTutorPage()) {
                var myCourseCompTpl = app.getHtmlTemplate('tutor/my-course');

                tutorSettings.uiChanged();
            } else {
                myCourseCompTpl = app.getHtmlTemplate('common/course');

                data.addAsRow = true;
            }

            $(".my-courses .entries").append(myCourseCompTpl(data));
            $('.my-courses .entries [data-toggle="tooltip"]').tooltip();

            if (app.urlhelper.isStudentPage()) {
                app.addFavoriteCourse(id);
            }
        }

        $("." + (favorite ? 'courses' : 'my-courses') + " .entries > div").sort(function (a, b) {
            return ($(a).data("sort")) > ($(b).data("sort")) ? 1 : -1;
        }).appendTo("." + (favorite ? 'courses' : 'my-courses') + " .entries");
    });

    // Start Student Methods
    $("body").delegate(".label.status-1", "click", function (e) {
        e.preventDefault();

        let id = $(this).parents('.course').attr('id'),
                url = $(this).attr('href'),
                reference = $(this).data('reference');

        student.joinTutoringSession(id, reference).then(function () {
            $(".tooltip").tooltip("hide");

            app.logEvent(new EventJoin(app, {
                action: 'Goboard',
                courseId: id,
                courseTutorRef: reference,
                roomUrl: url
            }));

            window.open(url, '_blank');
        });
    });

    $("body").delegate(".label.status-2", "click", function (e) {
        e.preventDefault();

        let id = $(this).parents('.course').attr('id'),
                url = $(this).data('url'),
                reference = $(this).data('reference');

        student.joinTutoringQueue(id, reference).then(function () {
            app.logEvent(new EventJoin(app, {
                action: 'Queue',
                courseId: id,
                courseTutorRef: reference,
                roomUrl: url
            }));
        });
    });

    $("body").delegate("#join-room-modal a.room-url", "click", function (e) {
        let url = $(this).attr('href'),
                course_id = $(this).data('course-id'),
                tutor_reference = $(this).data('tutor-reference'),
                queue_reference = $(this).data('queue-reference');

        tutor.updateTutoringQueueObjectStatus(course_id, tutor_reference, queue_reference, 2).then(data => {
            clearTimeout(app.queueClickTimer);

            app.logEvent(new EventJoin(app, {
                action: 'QueueGoboard',
                courseId: course_id,
                courseTutorRef: tutor_reference,
                courseTutorQueueRef: queue_reference,
                roomUrl: url
            }));

            $('#join-room-modal').modal('hide');
        });
    });
    // End Student Methods

    // Start Tutor Methods
    $("#customSwitch1").on("change", function (e) {
        if ($(this).data('auto-clicked')) {
            $(this).data('auto-clicked', false);
        } else {
            tutorSettings.uiChanged();
        }

        $('span, label', $(this).parent()).removeClass('font-weight-bold');

        if ($(this).prop("checked") === true) {
            $('label', $(this).parent()).addClass('font-weight-bold');
        } else {
            $('span', $(this).parent()).addClass('font-weight-bold');
        }
    });

    $("body").delegate(".my-status .custom-control.custom-switch span", "click", function () {
        $('#customSwitch1', $(this).parent()).click();
    });

    $("body").delegate(".course-room-url", "change input", function () {
        tutorSettings.uiChanged();

        let thisValue = this.value;

        if (thisValue.length > 1) {
            let urlDisplay = thisValue.trim();

            if (app.schoolhelper.isHostFromUniversityOfMichigan()) {
                let course_id = $(this).closest('.course').attr('id'),
                        parent = $('.my-courses #' + course_id),
                        parentUrlPrefix = $('.course-room-url-prefix', parent);

                thisValue = parentUrlPrefix.text() + urlDisplay;
            }

            if (app.urlhelper.isValidURL(thisValue)) {
                if (app.urlhelper.getDomain(thisValue).toLowerCase() == 'goboard.com') {
                    let url = thisValue.trim();

                    if (url != 'http://goboard.com/' && url != 'http://goboard.com' && url != 'https://goboard.com/' && url != 'https://goboard.com') {
                        $(this).data('valid', true).val(urlDisplay);
                        $(this).data('url', url);
                    }

                    return;
                }
            }
        }

        $(this).data('valid', false);
    });

    var typingTimer;
    var doneTypingInterval = 2000;
    $("body").delegate(".course-room-url", "keyup", function () {
        clearTimeout(typingTimer);

        $(this).val($(this).val().trim());

        typingTimer = setTimeout(function () {
            tutorSettings.uiChanged();
        }, doneTypingInterval);
    });

    $("body").delegate(".course-room-capacity", "change", function () {
        tutorSettings.uiChanged();
    });

    $("body").delegate("#save-status-button", "click", function () {
        tutorSettings.save();
    });
    // End Tutor Methods

    // Start Room Methods
    $("body").delegate(".end-student-session", "click", function () {
        let queueReference = $(this).data('reference');

        tutor.removeTutoringQueueObject(app.room.course_id, app.room.reference, queueReference);
    });

    $("body").delegate(".invite-student", "click", function () {
        let queueReference = $(this).data('reference');

        tutor.updateTutoringQueueObjectStatus(app.room.course_id, app.room.reference, queueReference, 3);

        if (typeof app.queueRoomClickTimers === 'undefined') {
            app.queueRoomClickTimers = [];
        }

        app.queueRoomClickTimers[queueReference];

        app.queueRoomClickTimers[queueReference] = setTimeout(function () {
            app.databaseStore.removeTutoringQueueObject(app.room.course_id, app.room.reference, queueReference);
        }, app.queueWaitTime);
    });
    // End Room Methods

    $("body").delegate("#logout", "click", function (e) {
        e.preventDefault();

        app.logout();

        window.location.replace($(this).attr('href'));
    });
});
