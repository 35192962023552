import axios from 'axios';

import CookieStore from './common/cookie-store.js';
import {urlhelper} from './common/url-helper.js';
import {schoolhelper} from './common/school-helper.js';

class UserDataObject {
    constructor(data) {
        const isConsideredTutr = (data) => {
            var isTutor = false;

            if (data.is_tutor > 0) {
                isTutor = true;
            } else if (data.canvas_roles) {
                if (data.canvas_roles.includes('Instructor')) {
                    isTutor = true;
                } else if (data.canvas_roles.includes('TeachingAssistant')) {
                    isTutor = true;
                }
            }

            return isTutor;
        };

        this.id = data.id;
        this.first_name = data.first_name || '';
        this.last_name = data.last_name || '';
        this.email_address = data.email_address || null;
        this.phone_number = data.phone_number || null;
        this.profile_picture = data.profile_picture || null;
        this.is_student = data.is_student ? 1 : 0;
        this.is_tutor = isConsideredTutr(data) ? 1 : 0;
        this.is_super = data.is_super ? 1 : 0;
        this.timezone = data.timezone || null;
        this.canonical_timezone = data.canonical_timezone || null;
        this.canvas_roles = data.canvas_roles ? data.canvas_roles : null;
    }
}

export default class Authentication {
    constructor() {
        //this.tmsApiTesting = process.env.NODE_ENV === "development" ? true : false;
        //this.tmsApiTesting = schoolhelper.isHostFromStaging ? true : false;
        this.tmsApiTesting = false;

        this.cookieStore = new CookieStore();
        this.cookieStore.setUserID();

        if (this.isLoggedIn() !== true) {
            if (urlhelper.isRoomPage() !== true) {
                if (urlhelper.isSsoRequest()) {
                    this.loginUserSso();
                } else {
                    if (window.name === 'tool_content') {
                        document.getElementById("page-container").innerHTML = 'LTI connection error<br><br>' + btoa(window.location.href);
                    } else {
                        window.location.replace(urlhelper.getTmsUrl(this.tmsApiTesting) + '/#/login?redirectTo=' + encodeURIComponent(window.location.protocol + '//' + window.location.host + '/?tms-sso='));
                    }
                }
            }
        } else {
            if (urlhelper.isSsoRequest()) {
                this.loginUserSso();
            } else if (urlhelper.isTutorPage()) {
                if (!this.isUserTutor()) {
                    window.location.replace('/#/');
                    window.location.reload();
                }
            }
        }
    }

    isLoggedIn() {
        let userData = this.getUserData();
        let isLoggedIn = false;

        if (typeof userData === 'object' && userData.id > 0) {
            isLoggedIn = true;
        }

        return isLoggedIn;
    }

    logout() {
        this.cookieStore.clearApp();
        this.cookieStore.setUserID();
    }

    getUserData() {
        return this.cookieStore.getUserData();
    }

    isUserTutor() {
        let userData = this.getUserData();

        if (typeof userData === 'object' && userData.is_tutor > 0) {
            return true;
        }

        return false;
    }

    loginUserSso() {
        let param = new URLSearchParams(window.location.search);

        if (!param.has('tms-sso')) {
            alert('Opps, authentication token error! #ATE001');

            window.location.replace('https://goboard.com/');

            return;
        }

        let params = new URLSearchParams(param.get('tms-sso')),
                authentication_token = params.has('auth_id') ? params.get('auth_id') : null,
                lti_roles = params.has('lti_roles') ? params.get('lti_roles') : null;

        if (!authentication_token) {
            alert('Opps, authentication token error! #ATE002');

            if (!lti_roles) {
                window.location.replace('https://goboard.com/');
            }

            return;
        }

        this.getSsoUserData({
            authentication_token: authentication_token,
            lti_roles: lti_roles
        }).then(() => {
            let userData = this.getUserData();

            if (typeof userData === 'object' && !(userData.id > 0)) {
                if (window.name === 'tool_content') {
                    document.getElementById("page-container").innerHTML = 'Opps, authentication token error! #ATE003<br><br>' + btoa(window.location.href);
                } else {
                    alert('Opps, authentication token error! #ATE003');

                    if (!this.isLoggedIn()) {
                        window.location.replace('https://goboard.com/');
                    }
                }
            } else {
                window.location.replace('/');
            }
        });
    }

    getSsoUserData(params) {
        return axios
                .get(urlhelper.getTmsUrl(this.tmsApiTesting) + '/api/v1/auth/verify-goboard-auth-id/' + params.authentication_token)
                .then((response) => {
                    let currentUserDataObject = this.getUserData();

                    if (currentUserDataObject.id !== response.data.id) {
                        this.logout();
                    }

                    const matches = (obj, source) =>
                        Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key]);

                    let newUserDataObject = new UserDataObject({
                        id: response.data.id,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        email_address: response.data.email,
                        phone_number: response.data.phone,
                        profile_picture: response.data.photo_url,
                        is_student: response.data.is_student,
                        is_tutor: response.data.is_tutor,
                        is_super: response.data.is_super,
                        timezone: response.data.timezone,
                        canonical_timezone: response.data.canonical_timezone,
                        canvas_roles: params.lti_roles
                    });

                    if (!matches(currentUserDataObject, newUserDataObject)) {
                        this.cookieStore.clearUserData();
                        this.cookieStore.setUserData(newUserDataObject);
                    }
                }).catch(error => {
            console.log(error);

            if (window.name === 'tool_content') {
                document.getElementById("page-container").innerHTML = 'Opps, user data error! #ATE004<br><br>' + btoa(window.location.href);
            } else {
                alert('Opps, user data error! #ATE004');

                if (!this.isLoggedIn()) {
                    window.location.replace('https://goboard.com/');
                }
            }
        });
    }

    toggleUserTutorStatus(user_id, status) {
        return axios
                .get(urlhelper.getTmsUrl(this.tmsApiTesting) + '/api/v1/update-goboard-tutor-status/' + user_id + '/' + status)
                .then((response) => {
                    let currentUserData = this.cookieStore.getUserData();

                    currentUserData.is_tutor = status;

                    this.cookieStore.setUserData(currentUserData);
                }).catch(error => {
            console.log(error.response)

            alert("Oops, please try again. #ATE005");

            window.location.replace('https://goboard.com/');
        });
    }

}
